import { unmountCookiePopup } from "@/lib/components/native/cookie-popup/container";
import {
  acceptAll,
  acceptCookies,
  type ConsentType,
  type ConsentTypes,
} from "@/lib/components/native/cookie-popup/state";
import { getCookie } from "@/lib/helpers/cookies";

function getStep2Element() {
  const step2Element = document.querySelector<HTMLFormElement>(
    "[data-cookie-popup='step-2']",
  );

  if (!step2Element) {
    throw new Error("Cookie consent popup step 2 element not found");
  }

  return step2Element;
}

function mountStep2() {
  const step2Element = getStep2Element();

  step2Element.addEventListener("submit", formSubmit);

  step2Element
    .querySelector("[data-cookie-popup=btn-accept]")
    ?.addEventListener("click", acceptClick);

  const consentCookie = getCookie("solvariCookieConsent");

  step2Element
    .querySelectorAll<HTMLInputElement>(".cookie-popup__option__input")
    .forEach((option) => mountOption(option, consentCookie));

  step2Element.style.display = "block";
}

function unmountStep2() {
  const step2Element = getStep2Element();

  step2Element.removeEventListener("submit", formSubmit);

  step2Element
    .querySelector("[data-cookie-popup=btn-accept]")
    ?.removeEventListener("click", acceptClick);

  step2Element
    .querySelectorAll<HTMLInputElement>(".cookie-popup__option__input")
    .forEach((element) => unmountOption(element));

  step2Element.style.display = "none";
}

function acceptClick() {
  acceptAll();
  unmountStep2();
  unmountCookiePopup();
}

function mountOption(
  optionElement: HTMLInputElement,
  consentCookie: ConsentTypes | null,
) {
  optionElement.addEventListener("change", optionChange);

  if (
    consentCookie?.includes(optionElement.getAttribute("value") as ConsentType)
  ) {
    optionElement.checked = true;
  }

  updateOptionCheckedState(optionElement);
}

function unmountOption(option: HTMLInputElement) {
  option.removeEventListener("change", optionChange);
}

function optionChange({ currentTarget }: Event) {
  updateOptionCheckedState(currentTarget as HTMLInputElement);
}

function updateOptionCheckedState(optionElement: HTMLInputElement) {
  const optionButton = optionElement.closest<HTMLLabelElement>(
    ".cookie-popup__option",
  );

  if (!optionButton) {
    return;
  }

  if (optionElement.checked) {
    optionButton.dataset.checked = "";
  } else {
    delete optionButton.dataset.checked;
  }
}

function formSubmit(event: SubmitEvent) {
  event.preventDefault();
  const formData = new FormData(event.currentTarget as HTMLFormElement);
  acceptCookies(formData.getAll("cookie-setting") as ConsentTypes);
  unmountStep2();
  unmountCookiePopup();
}

export { mountStep2 };
