import { unmountCookiePopup } from "@/lib/components/native/cookie-popup/container";
import { acceptAll } from "@/lib/components/native/cookie-popup/state";
import { mountStep2 } from "@/lib/components/native/cookie-popup/step2";

function getStep1Element() {
  const step1Element = document.querySelector<HTMLDivElement>(
    "[data-cookie-popup='step-1']",
  );

  if (!step1Element) {
    throw new Error("Cookie consent popup step 1 element not found");
  }

  return step1Element;
}

function mountStep1() {
  const step1Element = getStep1Element();

  step1Element
    .querySelector("[data-cookie-popup=btn-accept]")
    ?.addEventListener("click", acceptClick);

  step1Element
    .querySelector("[data-cookie-popup=btn-step-2]")
    ?.addEventListener("click", goToStep2);

  step1Element.style.display = "block";
}

function unmountStep1() {
  const step1Element = getStep1Element();

  step1Element
    .querySelector("[data-cookie-popup=btn-accept]")
    ?.addEventListener("click", acceptClick);

  step1Element
    .querySelector("[data-cookie-popup=btn-step-2]")
    ?.addEventListener("click", goToStep2);

  step1Element.style.display = "none";
}

function acceptClick() {
  acceptAll();
  unmountStep1();
  unmountCookiePopup();
}

function goToStep2() {
  unmountStep1();
  mountStep2();
}

export { mountStep1, unmountStep1 };
