import { mountStep1 } from "@/lib/components/native/cookie-popup/step1";

function getCookiePopupElement() {
  const element = document.querySelector<HTMLElement>(
    "[data-cookie-popup=container]",
  );

  if (!element) {
    throw new Error("Cookie consent popup element not found");
  }

  return element;
}

function mountCookiePopup() {
  getCookiePopupElement().style.display = "grid";
  mountStep1();
}

function unmountCookiePopup() {
  getCookiePopupElement().style.display = "none";
}

export { getCookiePopupElement, mountCookiePopup, unmountCookiePopup };
