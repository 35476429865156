import { getCookie, onSetCookie } from "@/lib/helpers/cookies";

import { getCookiePopupElement, mountCookiePopup } from "./container";
import { type ConsentTypes, sendConsentUpdateEvent } from "./state";

function initCookiePopup() {
  const consentCookie = getCookie("solvariCookieConsent");

  if (consentCookie) {
    sendConsentUpdateEvent(consentCookie);
  } else if (getCookiePopupElement().style.display === "grid") {
    mountCookiePopup();
  }

  document
    .querySelectorAll("[data-cookie-popup=btn-open]")
    .forEach((button) => button.addEventListener("click", mountCookiePopup));

  onSetCookie("solvariCookieConsent", sendConsentUpdateEvent);
}

export { initCookiePopup };
export type { ConsentTypes };
